import React from "react"
import styled from "styled-components"
import Scissors from "../../../icons/tools/split/Scissors.svg"

const CutStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 400px;

  svg {
    display: none;
    margin: 12px 0;
  }

  cursor: pointer;
  :hover {
    svg {
      display: block;
    }
    .shortLine {
      display: none;
    }
  }
  .cutted {
    .shortLine {
      display: none;
    }
    svg {
      display: block;
    }
  }
`

const Line = styled.div`
  min-height: 80px;
  width: 0px;
  border: 2px solid #6361ff;
`

const ShortLine = styled.div`
  min-height: 44px;
  width: 0px;
  border: 2px solid #6361ff;
`
function Cut({order}) {
  return (
    <CutStyle order={order} className="cut">
      <CutStyle>
        <Line />
        <ShortLine className="shortLine" />
        <Scissors />
        <Line />
      </CutStyle>
    </CutStyle>
  )
}

export default Cut
