import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"

export const SplitFile = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      DataLayer("Online tool", "Upload", "Split")
      window.fileName = res.data.message[0]
      client
        .get(
          `/splitTool?pages=${data.pages}&fileName=${window.fileName}${
            data.password ? `&password=${data.password}` : ""
          }`
        )
        .then(res => {
          DataLayer("Online tool", "Split", "Split")
          client
            .get(`/downloadSplit?fileName=${window.fileName}`, {
              //data.fileName
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = "pdfpro_split.zip"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Split")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Split")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error split", "Split")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Split")
    })
