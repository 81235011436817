import React from "react"
import ReactDOMServer from "react-dom/server"
import PdfdisplaySplit from "../split/PdfdisplaySplit"
import Cut from "../split/Cut"

export default function displayPdfSyncSplit(
  pdfData,
  fileData,
  appendTo,
  order,
  setFileRendered,
  removePassProtected,
  setErrorFiles,
  ErrorPopup,
  setPageNum,
  password
) {
  return new Promise(async (resolve, reject) => {
    let protectedPass = false
    const pdf = await window.pdfjsLib
      .getDocument({
        data: pdfData,
        password,
      })
      .promise.catch(() => {
        console.log("Password protected: ", fileData.name)
        // removePassProtected(fileData.name)
        // setErrorFiles(erF => [...erF, fileData.name])
        protectedPass = true

        let Prompt = document.getElementById("PasswordPrompt")
        let Enter = document.getElementById("EnterPassword")
        let PdfPasswordInput = document.getElementById("PdfPasswordInput")
        let PasswordFileName = document.getElementById("PasswordFileName")
        if (PasswordFileName) PasswordFileName.innerText = fileData.name
        if (PdfPasswordInput)
          PdfPasswordInput.value = window.password ? window.password : ""
        if (Prompt) Prompt.style.display = "flex"

        if (PdfPasswordInput && window.password) {
          PdfPasswordInput.style.outline = "2px solid rgba(253, 105, 103, 0.3)"
          PdfPasswordInput.style.border = "1px solid #FD6967"
        }

        if (Enter && window.password) {
          Enter.style.background = "#FD6967"
        }

        // This is hendling double event listeners that refues to be removed
        if (!window.order) window.order = 0
        window.order = window.order + 1
        let order = window.order

        function clickListener(event) {
          if (order == window.order) {
            Prompt.style.display = "none"
            window.password = PdfPasswordInput.value
            // PdfPasswordInput.value = ""
            console.log("event listener;", order)
            displayPdfSyncSplit(
              pdfData,
              fileData,
              appendTo,
              order,
              setFileRendered,
              removePassProtected,
              setErrorFiles,
              ErrorPopup,
              setPageNum,
              PdfPasswordInput.value
            )
          }
        }

        function keyboardListener(event) {
          if (event.key === "Enter" && order == window.order) {
            event.preventDefault()
            window.password = PdfPasswordInput.value
            // PdfPasswordInput.value = ""
            Prompt.style.display = "none"
            console.log("event listener;", order)
            displayPdfSyncSplit(
              pdfData,
              fileData,
              appendTo,
              order,
              setFileRendered,
              removePassProtected,
              setErrorFiles,
              ErrorPopup,
              setPageNum,
              PdfPasswordInput.value
            )
          }
        }

        Enter.addEventListener("click", clickListener)

        PdfPasswordInput.addEventListener("keydown", keyboardListener)
      })

    window.realPass = window.password
    window.password = undefined
    if (protectedPass) {
      setFileRendered(order)

      return resolve(false)
    }

    const pages = pdf.numPages

    if (pages > 100) {
      setErrorFiles(erF => [...erF, fileData.name])
      ErrorPopup("This file has more than 100 pages.")
      setFileRendered(order)

      return resolve(false)
    }

    if (pages == 1) {
      setErrorFiles(erF => [...erF, fileData.name])
      ErrorPopup("This file has only one page.")
      setFileRendered(1)

      return resolve(false)
    }

    window.pages = pages
    setPageNum(pages)

    for (let i = 0; i < pages; i++) {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: pages == 1 ? 0.585 : 0.337 })

      const canvas = document.createElement("canvas")

      const context = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginDown = "64px"

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise

      var dataURL = canvas.toDataURL()

      let str = JSON.stringify({ name: fileData.name, size: fileData.size })
      setFileRendered((i + 1) / pages)

      let pdfHolder = document.getElementById(appendTo)

      if (i + 1 != pages) {
        pdfHolder.innerHTML +=
          ReactDOMServer?.renderToString(
            <PdfdisplaySplit
              element={dataURL}
              name={i + 1}
              file={str}
              order={i + 1}
              oneDisplay={pages == 1}
            >
              {" "}
              test{" "}
            </PdfdisplaySplit>
          ) + ReactDOMServer?.renderToString(<Cut order={i + 1}></Cut>)
      } else {
        pdfHolder.innerHTML += ReactDOMServer?.renderToString(
          <PdfdisplaySplit element={dataURL} name={i + 1} order={i + 1}>
            {" "}
            test{" "}
          </PdfdisplaySplit>
        )
      }
    }

    setFileRendered(order)

    resolve(true)
  })
}
